import { FC } from 'react';

import { Placeholder } from '@sitecore/common';
import { Box, PageGrid } from '@sparky';

const Footer: FC = () => (
  <Box backgroundColor="backgroundPrimary">
    <PageGrid>
      <PageGrid.Item gridColumn={{ initial: '1/-1' }}>
        <Placeholder name="jss-footer-navigation" />
      </PageGrid.Item>
    </PageGrid>
    <Box
      backgroundColor="backgroundSecondary"
      paddingBottom={{ initial: '6', md: '10' }}
      paddingTop={{ initial: '24', xl: '32' }}>
      <PageGrid>
        <PageGrid.Item gridColumn={{ initial: '1/-1', md: '1/7' }}>
          <Box paddingBottom={{ initial: '5', md: '0' }}>
            <Placeholder name="jss-subfooter-left" />
          </Box>
        </PageGrid.Item>
        <PageGrid.Item gridColumn={{ initial: '1/-1', md: '7/13' }}>
          <Placeholder name="jss-subfooter-right" />
        </PageGrid.Item>
      </PageGrid>
    </Box>
  </Box>
);

export default Footer;
